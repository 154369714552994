export const baseurl = "https://node.cryptolabdex.com";

// console.log("jdhkjfgfhgh",baseurl)
// https://node-tusharbot.mobiloitte.io/api/v1/static/addStaticContent

let base = `${baseurl}/api/v1`;
let url = `${baseurl}/api/v1/admin`;
// let urlU = `${baseurl}/api/v1/user`;

const ApiConfig = {
  adminLogin: `${url}/loginWithEmail`,
  forgotPassword: `${url}/forgotPassword`,
  verifyOTP: `${url}/verifyOTP`,
  resendOTP: `${url}/resendOtp`,
  resetPassword: `${url}/resetPassword`,
  viewProfile: `${url}/viewProfile`,
  editProfile: `${url}/editProfile`,
  changePassword: `${url}/changePassword`,
  adminProfile: `${url}/adminProfile`,
  userList: `${url}/userList`,
  addUser: `${url}/addUser`,
  deleteUser: `${url}/deleteUser`,
  blockUnblockUser: `${url}/blockUnblockUser`,
  viewUser: `${url}/viewUser`,
  userApikey: `${url}/userApikey`,
  getAllWhitelist: `${url}/getAllWhitelist`,
  addWhitelist: `${url}/addWhitelist`,
  deleteFAQ: `${base}/static/deleteFAQ`,
  faqList: `${base}/static/faqList`,
  editFAQ: `${base}/static/editFAQ`,
  addFAQ: `${base}/static/addFAQ`,
  aboutus: `${url}/aboutus`,
  adminDashboard: `${url}/dashboard`,
  blockUnblockWhitelist: `${url}/blockUnblockWhitelist`,
  deleteWhitelist: `${url}/deleteWhitelist`,
  updateFaq: `${url}/updateFaq`,
  addStaticContent: `${base}/static/addStaticContent`,
  getstaticContent: `${url}/getstaticContent`,
  addUpdateStaticContent: `${url}/addUpdateStaticContent`,
  getInfluencerRequestList: `${url}/getInfluencerRequestList`,
  approveOrRejectInfluencer: `${url}/approveOrRejectInfluencer`,
  brandlists: `${url}/brandlists`,
  approveRejectBrand: `${url}/approveRejectBrand`,
  getInfluencerCategory: `${url}/getInfluencerCategory`,
  deleteInfluencerCategory: `${url}/deleteInfluencerCategory`,
  addInfluencerCategory: `${url}/addInfluencerCategory`,
  editInfluencerCategory: `${url}/editInfluencerCategory`,
  addCategory: `${url}/addCategory`,
  getCategory: `${url}/getCategory`,
  editCategory: `${url}/editCategory`,
  deleteNftCategory: `${url}/deleteNftCategory`,
  getTransactions: `${url}/getTransactions`,

  viewBrand: `${base}/brand/viewBrand`,

  getcategoryforEndpoint: `${url}/getcategoryforEndpoint`,
  addCategoryforEndpoint: `${url}/addCategoryforEndpoint`,
  getapiReferenceSubcategory: `${url}/getapiReferenceSubcategory`,
  addapiReferenceSubcategory: `${url}/addapiReferenceSubcategory`,
  endpointDescription: `${url}/endpointDescription`,
  deleteEndpoint: `${url}/deleteEndpoint`,
  blockUnblockEndpoint: `${url}/blockUnblockEndpoint`,
  deleteApiReferenceSubcategory: `${url}/deleteApiReferenceSubcategory`,
  deleteCategoryforEndpoint: `${url}/deleteCategoryforEndpoint`,

  viewEndPointDetails: `${url}/viewEndPointDetails`,
  editApiReferenceSubcategory: `${url}/editApiReferenceSubcategory`,
  viewapiReferenceSubcategory: `${url}/viewapiReferenceSubcategory`,
  endpointList: `${url}/endpointList`,

  getHitrates: `${url}/getHitrates`,
  editHitrates: `${url}/editHitrates`,

  addAnnouncement: `${url}/addAnnouncement`,
  editAnnouncement: `${url}/editAnnouncement`,
  getAllAnnouncement: `${url}/getAllAnnouncement`,
  deleteAnnouncement: `${url}/deleteAnnouncement`,
  blockUnblockAnnouncement: `${url}/blockUnblockAnnouncement`,

  addPartner: `${url}/addPartner`,
  editPartner: `${url}/editPartner`,
  partnerList: `${url}/partnerList`,
  deletePartner: `${url}/deletePartner`,
  blockUnblockPartner: `${url}/blockUnblockPartner`,
  getTransactions: `${url}/getTransactions`,

  // static
  staticContentList: `${base}/static/staticContentList`,
};
export default ApiConfig;
